import styled from 'styled-components'
import {
  H1 as Header1,
  H2 as Header2,
  H3 as Header3,
  H4 as Header4,
  H5 as Header5,
  H6 as Header6,
  P as Paragraph,
} from '@bootstrap-styled/v4'

import breakpoints from '../../util/style/breakpoints'

export const H1 = styled(Header1)`
  font-size: 2.5rem;
  line-height: 1.3;
  font-family: 'Playfair Display';
  ${breakpoints.md`
    line-height: 1.21;
    font-size: 3.5rem;
  `};
`

export const H2 = styled(Header2)`
  font-size: 2.25rem;
  line-height: 1.33;
  font-family: 'Playfair Display';
  ${breakpoints.md`
    line-height: 1.25;
    font-size: 3rem;
  `};
`

export const H3 = styled(Header3)`
  color: ${props => props.color && props.color};
  font-size: 2rem;
  line-height: 1.38;
  font-weight: 700;
  font-family: 'Playfair Display';
  ${breakpoints.md`
    line-height: 1.3;
    font-size: 2.5rem;
  `};
`

export const H4 = styled(Header4)`
  color: ${props => props.color && props.color};
  font-size: 1.75rem;
  line-height: 1.43;
  font-family: 'Playfair Display';
  ${breakpoints.md`
    line-height: 1.38;
    font-size: 2rem;
  `};
`

export const H5 = styled(Header5)`
  font-size: 1.25rem;
  font-family: 'Montserrat';
  font-weight: 300;
  line-height: 1.7;
  ${breakpoints.md`
    line-height: 1.58;
    font-size: 1.5rem;
  `};
`

export const H6 = styled(Header6)`
  font-size: 1.125rem;
  font-family: 'Montserrat';
  font-weight: 300;
  line-height: 1.67;
  ${breakpoints.md`
    line-height: 1.6;
    font-size: 1.25rem;
  `};
`

export const P = styled(Paragraph)`
  color: ${props => props.color && props.color};
  font-weight: 300;
  font-size: 1rem;
  line-height: 1.75;
`
