import React from 'react'
import PropTypes from 'prop-types'

const SVG = ({
  style = {},
  fill = '#000',
  className = '',
  width = '24',
  height = '24',
  viewBox = '0 0 24 24',
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    className={`svg-icon ${className || ''}`}
  >
    <path
      fill={fill}
      fillRule="evenodd"
      d="M10 18h4v-2h-4v2zM3 6v2h18V6H3zm3 7h12v-2H6v2z"
    />
  </svg>
)

SVG.propTypes = {
  style: PropTypes.object,
  fill: PropTypes.string,
  width: PropTypes.string.isRequired,
  className: PropTypes.string,
  viewBox: PropTypes.string,
}

SVG.defaultProps = {
  fill: '#B98752',
  style: {},
  viewBox: '0 0 24 24',
  className: '',
}

export default SVG
