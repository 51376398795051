import React from 'react'
import PropTypes from 'prop-types'

const SVG = ({
  style = {},
  fill = '#b98752',
  width = '100%',
  height = '100%',
  className = '',
  viewBox = '0 0 24 24',
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    className={`svg-icon ${className || ''}`}
  >
    <path
      fill={fill}
      fillRule="evenodd"
      d="M3 21V3h18v18H3zm2-2h14V5H5v14zM9 7h8v8h-2v-4.586l-6.293 6.293-1.414-1.414L13.586 9H9V7z"
    />
  </svg>
)

SVG.propTypes = {
  style: PropTypes.object,
  fill: PropTypes.string,
  width: PropTypes.string.isRequired,
  height: PropTypes.string,
  className: PropTypes.string,
  viewBox: PropTypes.string,
}

SVG.defaultProps = {
  style: {},
  height: '100%',
  fill: '#b98752',
  viewBox: '0 0 24 24',
  className: '',
}

export default SVG
