import React from 'react'
import PropTypes from 'prop-types'

import Divider from './Divider'
import Arrow from './Arrow'
import Vbo from './Vbo'
import Facebook from './Facebook'
import Linkedin from './Linkedin'
import Instagram from './Instagram'
import Twitter from './Twitter'
import Bathroom from './Bathroom'
import Bedroom from './Bedroom'
import LivingSpace from './LivingSpace'
import OutdoorSpace from './OutdoorSpace'
import ExternalLink from './ExternalLink'
import Chevron from './Chevron'
import Cross from './Cross'
import FilterIcon from './FilterIcon'
import PhoneIllustration from './PhoneIllustration'
import MailIllustration from './MailIllustration'
import StarFull from './StarFull'
import StarHalf from './StarHalf'
import Cookie from './Cookie'

const Icon = props => {
  switch (props.name) {
    case 'divider':
      return <Divider {...props} />
    case 'arrow':
      return <Arrow {...props} />
    case 'vbo':
      return <Vbo {...props} />
    case 'facebook':
      return <Facebook {...props} />
    case 'linkedin':
      return <Linkedin {...props} />
    case 'instagram':
      return <Instagram {...props} />
    case 'twitter':
      return <Twitter {...props} />
    case 'bathroom':
      return <Bathroom {...props} />
    case 'bedroom':
      return <Bedroom {...props} />
    case 'livingSpace':
      return <LivingSpace {...props} />
    case 'outdoorSpace':
      return <OutdoorSpace {...props} />
    case 'externalLink':
      return <ExternalLink {...props} />
    case 'chevron':
      return <Chevron {...props} />
    case 'cross':
      return <Cross {...props} />
    case 'filter':
      return <FilterIcon {...props} />
    case 'phone-illustration':
      return <PhoneIllustration {...props} />
    case 'mail-illustration':
      return <MailIllustration {...props} />
    case 'star-full':
      return <StarFull {...props} />
    case 'star-half':
      return <StarHalf {...props} />
    case 'cookie':
      return <Cookie {...props} />
    default:
      return <div />
  }
}

Icon.propTypes = {
  name: PropTypes.string.isRequired
}

export default Icon
