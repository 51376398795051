import React from 'react'
import PropTypes from 'prop-types'

const SVG = ({
  style = {},
  fill = '#b98752',
  width = '64',
  height = '56',
  className = '',
  viewBox = '0 0 64 56',
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    className={`svg-icon ${className || ''}`}
  >
    <g fill="none" fillRule="evenodd">
      <g fill={fill}>
        <g>
          <path
            d="M1 8l.001.002H1v39.999h63V8.002h-.001L64 8H1zm38.769 20.001L62.787 9.002H63v38h-.21L39.769 28.001zM3.783 9.002h57.434L32.5 32.704 3.783 9.002zM2 9.002h.213l23.018 18.999L2.21 47.002H2v-38zM38.984 28.65l22.234 18.352H3.782L26.016 28.65l6.484 5.351 6.484-5.351z"
            transform="translate(-72 -1191) translate(72 1191)"
          />
        </g>
      </g>
    </g>
  </svg>
)

SVG.propTypes = {
  style: PropTypes.object,
  fill: PropTypes.string,
  width: PropTypes.string.isRequired,
  height: PropTypes.string,
  className: PropTypes.string,
  viewBox: PropTypes.string,
}

SVG.defaultProps = {
  style: {},
  fill: '#B98752',
  height: '64',
  width: '56',
  viewBox: '0 0 64 56',
  className: '',
}

export default SVG
