import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const IconWrapper = styled.div`
  svg {
    width: 33px;
    height: 54px;
  }
`

const SVG = ({
  style = {},
  fill = '#b98752',
  width = '33',
  height = '54',
  viewBox = '0 0 33 54',
}) => (
  <IconWrapper>
    <svg
      width={width}
      style={style}
      height={height}
      viewBox={viewBox}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="Symbols"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Illustrations/Contact/Phone"
          transform="translate(-16.000000, -1.000000)"
          fill={fill}
        >
          <path
            d="M49,1 L49,55 L16,55 L16,1 L49,1 Z M48.001,2 L17,2 L17,54 L48.001,54 L48.001,2 Z M32.5,50 C33.327,50 34,50.673 34,51.5 C34,52.327 33.327,53 32.5,53 C31.673,53 31,52.327 31,51.5 C31,50.673 31.673,50 32.5,50 Z M32.5,51 C32.225,51 32,51.224 32,51.5 C32,51.776 32.225,52 32.5,52 C32.775,52 33,51.776 33,51.5 C33,51.224 32.775,51 32.5,51 Z M46,7 L46,49 L19,49 L19,7 L46,7 Z M45,8 L20,8 L20,48 L45,48 L45,8 Z M35.5,4 L35.5,5 L29.5,5 L29.5,4 L35.5,4 Z"
            id="🎨-Color"
          />
        </g>
      </g>
    </svg>
  </IconWrapper>
)

SVG.propTypes = {
  style: PropTypes.object,
  fill: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  viewBox: PropTypes.string,
}

SVG.defaultProps = {
  style: {},
  fill: '#B98752',
  height: '33',
  width: '54',
  viewBox: '0 0 33 54',
}

export default SVG
