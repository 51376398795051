import React from 'react'
import PropTypes from 'prop-types'

const SVG = ({
  style = {},
  fill = '#b98752',
  width = '24',
  height = '24',
  className = '',
  viewBox = '0 0 24 24',
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    className={`svg-icon ${className || ''}`}
  >
    <path
      fill={fill}
      fillRule="evenodd"
      d="M16 9V6a2 2 0 0 1 4 0v3h2v4h-2v7h-4v-7h-2v7h-4v-7H8v7H4v-7H2V9h2V6a2 2 0 0 1 4 0v3h2V6a2 2 0 0 1 4 0v3h2zm1 0h2V6a1.001 1.001 0 0 0-2 0v3zm0 4v6h2v-6h-2zm-4 0h-2v6h2v-6zm0-4V6a1.001 1.001 0 0 0-2 0v3h2zM5 9h2V6a1.001 1.001 0 0 0-2 0v3zm0 4v6h2v-6H5zm-2-1h18v-2H3v2z"
    />
  </svg>
)

SVG.propTypes = {
  style: PropTypes.object,
  fill: PropTypes.string,
  width: PropTypes.string.isRequired,
  height: PropTypes.string,
  className: PropTypes.string,
  viewBox: PropTypes.string,
}

SVG.defaultProps = {
  style: {},
  height: '100%',
  fill: '#b98752',
  viewBox: '0 0 24 24',
  className: '',
}

export default SVG
