import styledNormalize from 'styled-normalize'
import { createGlobalStyle } from 'styled-components'
import breakpoints from './breakpoints'
import { colors } from './theme'

const GlobalStyles = createGlobalStyle`
  ${styledNormalize}

  * {
    box-sizing: border-box;
  }

  html,
  body {
    min-height: 100%;
    min-width: 320px;
    padding: 0;
    margin: 0;
    font-family: "Montserrat", Arial, Helvetica, sans-serif;
    background-color: ${colors.gray700} !important;
  }

 .object-detail-lightbox {
    .ril__toolbar {
      background: transparent;
    }
  }

  p {
    color: ${colors.white};
  }

  a {
    text-decoration: none;
    color: #b98752;
  }

  .border-top-2p {
    border-top: solid ${colors.gray500} 2px !important;
  }

  .border-bottom-2p {
    border-bottom: solid ${colors.gray500} 2px !important;
  }

  .padding-b-3-5r {
    padding-bottom: 3.5rem;
  }

  .margin-b-3-5r {
    margin-bottom: 3.5rem !important;
  }
  ul {
    li {
      color: white;
    }
  }

  .font-weight-medium {
    font-weight: 500 !important;
  }

  hr {
    background: #393939;
    height: 1px;
    border: none;
    margin: 0;
  }

  .negative-margin-mobile {
    margin-left: -1.5rem;
    margin-right: -1.5rem;

    ${breakpoints.md`
      margin-left: inherit;
      margin-right: inherit;
    `};
  }

  ul {
    &.check-mark-list {
      list-style: none;
      font-weight: 300;
      padding-left: 6px;
      li {
        margin-bottom: 8px;
        &:last-child {
          margin-bottom: 0;
        }
        &::before{
          content: '';
          display: inline-block;
          margin-right: 16px;
          width: 6px;
          height: 14px;
          border: solid #b98752;
          border-width: 0 2px 2px 0;
          transform: rotate(45deg);
        }
      }
    }
  }

  .fade-link > {
    opacity: 1;
    transition: all 0.3s ease-in-out;

    &:hover {
      transition: all 0.3s ease-in-out;
      opacity: 70%;
    }
  }

`

export default GlobalStyles
