import React from 'react'
import { IntlContextConsumer, changeLocale } from 'gatsby-plugin-intl'
import styled from 'styled-components'
import breakpoints from '../../util/style/breakpoints'

const languageName = {
  nl: 'NL',
  en: 'EN',
}

const Language = styled.a`
  font-size: 0.9375rem;
  font-weight: 600;
  margin-right: 1.25rem;
  color: ${props => props.theme['$headings-color']};
  text-decoration: none;
  cursor: pointer;

  ${breakpoints.lg`
    margin-right: 1.5rem;
  `};

  &:last-child {
    margin-right: 0;
  }

`

const LanguageSwitch = () => {
  return (
    <div>
      <IntlContextConsumer>
        {({ languages, language: currentLocale }) =>
          languages.map(language => (
            <Language
              key={language}
              onClick={() => changeLocale(language)}
              style={{
                borderBottom: currentLocale === language ? `2px solid white` : `none`,
                paddingBottom: currentLocale === language ? `10px` : `0`,
              }}
            >
              {languageName[language]}
            </Language>
          ))
        }
      </IntlContextConsumer>
    </div>
  )
}

export default LanguageSwitch
