import React from 'react'
import PropTypes from 'prop-types'

const SVG = ({
  style = {},
  fill = '#000',
  width = '100%',
  className = '',
  viewBox = '0 0 40 26',
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    style={style}
    height={width}
    viewBox={viewBox}
    className={`svg-icon ${className || ''}`}
  >
    <path
      fill={fill}
      fillRule="evenodd"
      d="M14.707 4.293l-1.414 1.414L18.586 11H2v2h16.586l-5.293 5.293 1.414 1.414L22.414 12z"
    />
  </svg>
)

SVG.propTypes = {
  style: PropTypes.object,
  fill: PropTypes.string,
  width: PropTypes.string.isRequired,
  className: PropTypes.string,
  viewBox: PropTypes.string,
}

SVG.defaultProps = {
  fill: '#B98752',
  style: {},
  viewBox: '0 0 24 24',
  className: '',
}

export default SVG
