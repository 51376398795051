import React from 'react'
import PropTypes from 'prop-types'

const SVG = ({
  style = {},
  fill = '#B98752',
  width = '24',
  height = '24',
  className = '',
  viewBox = '0 0 24 24',
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    className={`svg-icon ${className || ''}`}
  >
    <g fill="none" fillRule="evenodd">
      <g fill={fill}>
        <g>
          <path
            d="M12 2c5.522 0 10 4.477 10 10s-4.478 10-10 10C6.477 22 2 17.523 2 12c0-1.681.42-3.263 1.152-4.653C3.663 7.75 4.298 8 5 8c1.657 0 3-1.343 3-3 0-.701-.25-1.337-.654-1.848C8.737 2.419 10.319 2 12 2zm0 2c-.703 0-1.39.089-2.055.267.036.241.055.486.055.733 0 2.757-2.244 5-5 5-.247 0-.493-.018-.733-.054C4.089 10.61 4 11.297 4 12l.005.09c.155-.054.32-.09.495-.09.828 0 1.5.671 1.5 1.5 0 .799-.627 1.446-1.414 1.491C5.773 17.924 8.646 20 12 20c1.667 0 3.215-.514 4.499-1.39-.304-.275-.499-.668-.499-1.11 0-.829.672-1.5 1.5-1.5.442 0 .836.195 1.11.499C19.486 15.216 20 13.667 20 12c0-.823-.125-1.617-.358-2.364C19.016 11.027 17.625 12 16 12c-2.209 0-4-1.791-4-4 0-1.625.972-3.017 2.364-3.643C13.616 4.125 12.822 4 12 4zm-1 8c1.657 0 3 1.343 3 3s-1.343 3-3 3-3-1.343-3-3 1.343-3 3-3zm0 2c-.552 0-1 .449-1 1 0 .551.448 1 1 1s1-.449 1-1c0-.551-.448-1-1-1zm5-8c-1.103 0-2 .897-2 2s.897 2 2 2c1.102 0 2-.897 2-2s-.898-2-2-2z"
            transform="translate(-600 -624) translate(600 624)"
          />
        </g>
      </g>
    </g>
  </svg>
)

SVG.propTypes = {
  style: PropTypes.object,
  fill: PropTypes.string,
  width: PropTypes.string.isRequired,
  height: PropTypes.string,
  className: PropTypes.string,
  viewBox: PropTypes.string,
}

SVG.defaultProps = {
  style: {},
  height: '24',
  fill: '#B98752',
  viewBox: '0 0 24 24',
  className: '',
}

export default SVG
