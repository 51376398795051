export const colors = {
  primary400: '#6f5131',
  primary300: '#b98752',
  primary200: '#dcc3a9',
  primary100: '#f8f3ee',
  gray700: '#171717',
  gray600: '#1f1f1f',
  gray500: '#292929',
  gray300: '#888888',
  gray200: '#dddddd',
  gray100: '#f5f5f5',
  warning: '#f5a623',
  error: '#d0021b',
  white: '#ffffff',
}

export const myTheme = {
  '$brand-primary': colors.primary400,
  '$primary': colors.primary300,
  '$brand-primary300': colors.primary300,
  '$headings-color': colors.white,
  '$body-bg': colors.gray700,
  '$font-family-base': 'Montserrat',
  '$headings-font-family': 'Playfair Display',
  '$small-font-size': '0.8125rem',
  '$brand-warning': colors.warning,
  '$brand-danger': colors.error,
  '$nav-link-padding-x': '255px',
  '$text-muted': colors.gray300,
  '$btn-primary-color': colors.gray700,
  '$btn-secondary-color': colors.primary200,
  '$btn-secondary-border': colors.primary200,
  '$btn-secondary-bg': colors.gray700,
  '$btn-border-radius': '0',
  '$btn-padding-x': '32px',
  '$btn-padding-y': '14px',
  '$gray-dark': colors.gray700,
  $gray: colors.gray600,
  '$gray-light': colors.gray500,
  '$gray-lighter': colors.gray400,
  '$gray-lightest': colors.gray300,
  '$grid-breakpoints': {
    xs: '0',
    sm: '768px',
    md: '960px',
    lg: '1280px',
    xl: '1920px',
    xxl: '2600px',
  },
  '$container-max-widths': {
    sm: '560px',
    md: '912px',
    lg: '1224px',
    xl: '1416px',
  }
}
