import React from 'react'
import PropTypes from 'prop-types'

const SVG = ({
  style = {},
  fill = '#B98752',
  width = '100%',
  height = '100%',
  className = '',
  viewBox = '0 0 24 24',
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    style={style}
    viewBox={viewBox}
    className={`svg-icon ${className || ''}`}
  >
    <path
      fill={fill}
      fillRule="evenodd"
      d="M24 23.25h-5.304v-7.91c0-2.07-.83-3.483-2.655-3.483-1.395 0-2.171.953-2.533 1.873-.135.33-.114.79-.114 1.249v8.271H8.14s.068-14.011 0-15.285h5.255v2.399c.31-1.049 1.99-2.546 4.67-2.546 3.324 0 5.936 2.199 5.936 6.933v8.499zM2.825 6.053h-.034C1.098 6.053 0 4.885 0 3.403 0 1.894 1.13.75 2.857.75c1.726 0 2.787 1.141 2.821 2.65 0 1.48-1.095 2.653-2.853 2.653zM.605 7.965h4.678V23.25H.605V7.965z"
    />
  </svg>
)

SVG.propTypes = {
  style: PropTypes.object,
  fill: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
  height: PropTypes.string,
  className: PropTypes.string,
  viewBox: PropTypes.string,
}

SVG.defaultProps = {
  style: {},
  height: '100%',
  viewBox: '0 0 24 24',
  className: '',
}

export default SVG
