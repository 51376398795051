import React from 'react'
import PropTypes from 'prop-types'

const SVG = ({
  style = {},
  fill = '#B98752',
  width = '32',
  height = '32',
  className = '',
  viewBox = '0 0 44 44',
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    className={`svg-icon ${className || ''}`}
  >
    <path
      fill={fill}
      fillRule="evenodd"
      d="M31.192 11.393l1.415 1.415-9.193 9.191 9.193 9.193-1.415 1.415L22 23.414l-9.191 9.193-1.415-1.415L20.585 22l-9.192-9.191 1.415-1.415 9.191 9.192 9.193-9.192z"
    />
  </svg>
)

SVG.propTypes = {
  style: PropTypes.object,
  fill: PropTypes.string,
  width: PropTypes.string.isRequired,
  height: PropTypes.string,
  className: PropTypes.string,
  viewBox: PropTypes.string,
}

SVG.defaultProps = {
  style: {},
  fill: '#B98752',
  height: '100%',
  viewBox: '0 0 44 44',
  className: '',
}

export default SVG
