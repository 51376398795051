import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const Info = styled.span`
    color: ${props => props.color};
    font-size: 0.6rem;
    font-weight: 600;
    border: solid 2px ${props => props.theme['$brand-primary300']};
    border-radius: 50%;
    width: 17px;
    height: 17px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 10px;
    span {
        margin-left: -1px;
        margin-top: 1px;
        text-align: center;
    }
`
const InfoBtn = ({ color }) => (
    <Info color={color}>
        <span>i</span>
    </Info>
)

InfoBtn.propTypes = {
  color: PropTypes.string,
}

InfoBtn.defaultProps = {
  color: '#b98752',
}

export default InfoBtn
