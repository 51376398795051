import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import breakpoints from '../../util/style/breakpoints'
import OutboundLink from '../Link/OutboundLink'

const style = `
  font-size: 1rem;
  font-family: 'Montserrat';
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  padding: 0.875rem 2rem;
  width: 100%;
  transition: all 0.2s ease-in-out;

  &:hover {
    cursor: pointer;
  }
`

const SecondaryBtnAsLink = styled(OutboundLink)`
  ${style}
  color: ${props => props.theme['$brand-primary']};
  background-color: ${props => props.color};

  &:hover {
    background-color: #dcc3a9;
  }

  ${breakpoints.md`
    width: auto;
  `};

`

const SecondaryBtnAsButton = styled.button`
  ${style}
  color: ${props => props.theme['$brand-primary']};
  background-color: ${props => props.color};

  &:hover {
    background-color: #dcc3a9;
  }

  ${breakpoints.md`
    width: auto;
  `};

`

const SecondaryBtn = props => {
  switch (props.type) {
    case 'link':
      return <SecondaryBtnAsLink {...props} />
    case 'button':
      return <SecondaryBtnAsButton {...props} />
    default:
      return <div />
  }
}

SecondaryBtn.propTypes = {
  color: PropTypes.string,
  type: PropTypes.string.isRequired,
}

SecondaryBtn.defaultProps = {
  color: '#ffffff',
}

export default SecondaryBtn
