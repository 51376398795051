import React from 'react'
import PropTypes from 'prop-types'

const SVG = ({
  style = {},
  fill = '#B98752',
  width = '100%',
  height = '100%',
  className = '',
  viewBox = '0 0 16 16',
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    style={style}
    viewBox={viewBox}
    className={`svg-icon ${className || ''}`}
  >
    <path
      fill={fill}
      fillRule="evenodd"
      d="M9.342 16V8.702h2.653l.398-2.845H9.342V4.041c0-.823.248-1.384 1.527-1.384H12.5V.111A23.603 23.603 0 0 0 10.123 0C7.77 0 6.16 1.326 6.16 3.76v2.097H3.5v2.845h2.66V16h3.182z"
    />
  </svg>
)

SVG.propTypes = {
  style: PropTypes.object,
  fill: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
  height: PropTypes.string,
  className: PropTypes.string,
  viewBox: PropTypes.string,
}

SVG.defaultProps = {
  style: {},
  height: '100%',
  viewBox: '0 0 16 16',
  className: '',
}

export default SVG
