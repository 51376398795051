import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import breakpoints from '../../util/style/breakpoints'
import OutboundLink from '../Link/OutboundLink'
import { Link } from '../Link'

const style = `
  font-size: 1rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme['$brand-primary300']};
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  padding: 0.875rem 2rem;
  width: 100%;
  transition: all 0.2s ease-in-out;

  &:hover {
    cursor: pointer;
  }
`

const PrimaryInverseBtnAsLink = styled(OutboundLink)`
  ${style}
  background-color: ${(props) => props.color};
  color: ${(props) => props.theme['$gray-dark']};
  border: 1px solid ${(props) => props.color};

  &:hover {
    color: ${(props) => props.color};
    background-color: ${(props) => props.theme['$gray-dark']};
  }

  ${breakpoints.md`
    width: auto;
  `};
`

const PrimaryInverseBtnAsButton = styled.button`
  ${style}
  background-color: ${(props) => props.color};
  color: ${(props) => props.theme['$gray-dark']};
  border: 1px solid ${(props) => props.color};

  &:hover {
    color: ${(props) => props.color};
    background-color: ${(props) => props.theme['$gray-dark']};
  }

  ${breakpoints.md`
    width: auto;
  `};
`

const PrimaryInverseBtnAsInternalLink = styled(Link)`
  ${style}
  background-color: ${(props) => props.color};
  color: ${(props) => props.theme['$gray-dark']};
  border: 1px solid ${(props) => props.color};

  &:hover {
    color: ${(props) => props.color};
    background-color: ${(props) => props.theme['$gray-dark']};
  }

  ${breakpoints.md`
    width: auto;
  `};
`

const PrimaryBtn = (props) => {
  switch (props.type) {
    case 'link':
      return <PrimaryInverseBtnAsLink {...props} />
    case 'button':
      return <PrimaryInverseBtnAsButton {...props} />
    case 'internal-link':
      return <PrimaryInverseBtnAsInternalLink {...props} />
    default:
      return <div />
  }
}

PrimaryBtn.propTypes = {
  color: PropTypes.string,
  type: PropTypes.string.isRequired,
}

PrimaryBtn.defaultProps = {
  color: '#b98752',
}

export default PrimaryBtn
