import React from 'react'
import PropTypes from 'prop-types'

import PrimaryBtn from './PrimaryBtn'
import PrimaryInverseBtn from './PrimaryInverseBtn'
import SecondaryBtn from './SecondaryBtn'
import InfoBtn from './InfoBtn'

const Button = props => {
  switch (props.name) {
    case 'primary':
      return <PrimaryBtn {...props} />
    case 'primary-inverse':
      return <PrimaryInverseBtn {...props} />
    case 'secondary':
      return <SecondaryBtn {...props} />
    case 'info':
      return <InfoBtn {...props} />
    default:
      return <div />
  }
}

Button.propTypes = {
  name: PropTypes.string.isRequired,
}

export default Button
