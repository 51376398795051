import React from 'react'
import PropTypes from 'prop-types'

const SVG = ({
  style = {},
  fill = '#B98752',
  width = '24',
  height = '24',
  className = '',
  viewBox = '0 0 24 24',
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    className={`svg-icon ${className || ''}`}
  >
    <g fill="none" fillRule="evenodd">
      <g>
        <g>
          <path
            fill="#393939"
            d="M14.43 10L12 2 9.57 10 2 10 8.18 14.41 5.83 22 12 17.31 18.18 22 15.83 14.41 22 10z"
            transform="translate(-264.000000, -672.000000) translate(264.000000, 672.000000)"
          />
          <path
            fill={fill}
            d="M12 2v15.31L5.83 22l2.35-7.59L2 10h7.57L12 2z"
            transform="translate(-264.000000, -672.000000) translate(264.000000, 672.000000)"
          />
        </g>
      </g>
    </g>
  </svg>
)

SVG.propTypes = {
  style: PropTypes.object,
  fill: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  className: PropTypes.string,
  viewBox: PropTypes.string,
}

SVG.defaultProps = {
  style: {},
  height: '100%',
  width: '24',
  fill: '#B98752',
  viewBox: '0 0 24 24',
  className: '',
}

export default SVG
