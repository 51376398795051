import React from 'react'
import PropTypes from 'prop-types'

const SVG = ({
  style = {},
  fill = '#b98752',
  width = '100%',
  height = '100%',
  className = '',
  viewBox = '0 0 48 16',
}) => (
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ''}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      fill={fill}
      fillRule="evenodd"
      d="M12 11.707l6-6 6 6 6-6 6 6 6-6 5.646 5.647.708-.708L42 4.293l-6 6-6-6-6 6-6-6-6 6-6-6-6.354 6.353.708.708L6 5.707z"
    />
  </svg>
)

SVG.propTypes = {
  style: PropTypes.object,
  fill: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
  height: PropTypes.string,
  className: PropTypes.string,
  viewBox: PropTypes.string,
}

SVG.defaultProps = {
  style: {},
  height: '100%',
  viewBox: '0 0 48 16',
  className: ''
}

export default SVG
