import React from 'react'
import PropTypes from 'prop-types'
import { BootstrapProvider } from '@bootstrap-styled/provider'
import GlobalStyles from '../../util/style/GlobalStyles'
import { myTheme } from '../../util/style/theme'
import Header from '../Header'
import CookieConsent from '../CookieConsent'

const Layout = ({ children }) => (
  <BootstrapProvider theme={myTheme}>
    <GlobalStyles />
    <Header/>
    {children}
    <CookieConsent />
  </BootstrapProvider>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
