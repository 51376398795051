import React from 'react'
import PropTypes from 'prop-types'

const SVG = ({
  style = {},
  fill = '#888',
  width = '100%',
  height = '100%',
  className = '',
  viewBox = '0 0 84 40',
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    style={style}
    viewBox={viewBox}
    className={`svg-icon ${className || ''}`}
  >
    <path
      fill={fill}
      fillRule="evenodd"
      d="M48.018 22.543l3.012-9.105h4.011l-5.101 13.16h-4.068l-5.05-13.16h4.183l3.013 9.105zm15.845.807c.294-.224.442-.532.442-.92a.995.995 0 0 0-.442-.856c-.296-.205-.695-.3-1.195-.288h-2.374v2.384h2.374c.5.012.899-.094 1.195-.32zm-3.57-6.983V18.6h2.375c.388 0 .696-.103.92-.31.228-.206.34-.485.34-.835 0-.338-.112-.604-.34-.798-.224-.195-.532-.29-.92-.29h-2.374zm5.405 3.227c.804.163 1.448.548 1.93 1.156.484.607.726 1.33.726 2.168 0 .74-.213 1.387-.64 1.943-.427.557-1.035.984-1.817 1.286-.785.3-1.699.451-2.741.451h-6.76v-13.16h6.478c1.018 0 1.909.135 2.673.404.767.268 1.357.654 1.77 1.153a2.68 2.68 0 0 1 .622 1.764c0 .665-.201 1.254-.601 1.765-.403.514-.949.87-1.64 1.07zm13.877 2.244a3.989 3.989 0 0 0 .415-1.83c0-.662-.14-1.268-.415-1.812a3.273 3.273 0 0 0-1.13-1.286 2.827 2.827 0 0 0-1.582-.469c-.576 0-1.114.157-1.61.47a3.408 3.408 0 0 0-1.187 1.285 3.76 3.76 0 0 0-.441 1.812c0 .664.148 1.272.441 1.821.295.55.691.987 1.188 1.305.495.32 1.033.479 1.61.479a2.8 2.8 0 0 0 1.572-.48c.483-.317.863-.75 1.14-1.295zm.913-7.761c1.098.588 1.959 1.397 2.58 2.43.622 1.034.932 2.192.932 3.482 0 1.303-.31 2.476-.931 3.52a6.584 6.584 0 0 1-2.581 2.46c-1.099.594-2.326.892-3.68.892-1.37 0-2.6-.298-3.692-.893a6.6 6.6 0 0 1-2.57-2.46c-.622-1.043-.933-2.216-.933-3.519 0-1.29.31-2.448.932-3.482.621-1.033 1.48-1.842 2.57-2.43 1.092-.59 2.323-.883 3.692-.883 1.355 0 2.582.293 3.681.883zM1.023 29.41l16.352 9.414V26.598h8.176v-9.8l2.043 1.176v-2.062l-10.22-5.883V1.175L1.024 10.588V29.41zM17.885.293l16.353 9.412.51.294v20l-.51.293-16.353 9.415-.51.293-.511-.293L.51 30.292 0 29.999v-20l.511-.294L16.864.293l.51-.293.511.293zM7.155 17.974v-2.062l10.22-5.883v16.569H9.199v-9.8l-2.045 1.176z"
    />
  </svg>
)

SVG.propTypes = {
  style: PropTypes.object,
  fill: PropTypes.string,
  width: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
  className: PropTypes.string,
  viewBox: PropTypes.string,
}

SVG.defaultProps = {
  fill: '#B98752',
  style: {},
  viewBox: '0 0 84 40',
  className: '',
}

export default SVG
