import React from 'react'
import PropTypes from 'prop-types'

const SVG = ({
  style = {},
  fill = '#b98752',
  width = '24',
  height = '24',
  className = '',
  viewBox = '0 0 24 24',
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    className={`svg-icon ${className || ''}`}
  >
    <path
      fill={fill}
      fillRule="evenodd"
      d="M17.001 11V7h-9v9H7V7H4v13.001h3V18h1.001v2.001h9V12H10v-1h7.001zM3 21V6h15v15H3zM17 3V2h1v3h-1V4H4v1H3V2h1v1h13zm3 17V7h-1V6h3v1h-1v13h1v1h-3v-1h1z"
    />
  </svg>
)

SVG.propTypes = {
  style: PropTypes.object,
  fill: PropTypes.string,
  width: PropTypes.string.isRequired,
  height: PropTypes.string,
  className: PropTypes.string,
  viewBox: PropTypes.string,
}

SVG.defaultProps = {
  style: {},
  height: '100%',
  fill: '#b98752',
  viewBox: '0 0 24 24',
  className: '',
}

export default SVG
