import React, { useState, useEffect } from 'react'
import { useLocation } from '@reach/router'
import styled from 'styled-components'
import { useCookies } from 'react-cookie'
import { injectIntl } from 'gatsby-plugin-intl'
import { initializeAndTrack } from 'gatsby-plugin-gdpr-cookies'
import breakpoints from '../../util/style/breakpoints'
import Icon from '../Icons'
import Button from '../Buttons'
import { H5, P } from '../Typography'

const CookiesContainer = styled.div`
  background-color: ${props => props.theme['$gray']};
  min-height: 56px;
  padding: 0 1.5rem;
  border-radius: 3px;
  ${breakpoints.md`
    min-height: 28px;
  `};

  display: ${(props) => (props.showCookie ? 'flex' : 'none')};
  position: fixed;
  padding: 16px;
  bottom: 16px;
  right: 16px;
  left: 16px;
  z-index: 998;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.08);
  button {
    margin-top: 16px;
    display: block;
  }

  ${breakpoints.md`
    padding: 24px;
    max-width: 384px;
    bottom: 48px;
    right: 48px;
    left: unset;
    button {
      display: inline-block;
      margin-right: 16px;
    }
  `};
`
const Column = styled.div`
  svg {
    margin-right: 16px;
    height: 32px;
  }
`

const CloseButton = styled.div`
  position: absolute;
  cursor: pointer;
  top: 16px;
  right: 16px;
`

const CookieConsent = ({ intl }) => {
  const location = useLocation()
  const [cookies, setCookie] = useCookies(['matton-residence-gdpr-google-analytics']);
  const [showCookie, setShowCookie] = useState(false)

  const startTracking = () => {
    setCookie('matton-residence-gdpr-google-analytics', 'true', { path: '/' })
    initializeAndTrack(location)
    setShowCookie(false)
  }

  useEffect(() => {
    if (
      typeof cookies['matton-residence-gdpr-google-analytics'] === 'undefined' &&
      cookies['matton-residence-gdpr-google-analytics'] !== 'true'
    ) {
      setShowCookie(true)
    }
  }, [])
  return (
    <CookiesContainer showCookie={showCookie}>
      <Column>
        <Icon width="32" name="cookie" />
      </Column>
      <Column>
        <CloseButton onClick={() => setShowCookie(false)}>
          <Icon width="24" name="cross" />
        </CloseButton>
        <H5>{intl.formatMessage({ id: 'HAVE_A_COOKIE' })}</H5>
        <P>{intl.formatMessage({ id: 'GOOGLE_ANALYTICS_TEXT' })}</P>
        <Button
          name="primary"
          type="button"
          className="mr-md-2"
          from="cookie-consent"
          onClick={() => startTracking()}
        >
          {intl.formatMessage({ id: 'ACCEPT_COOKIE_BTN' })}
        </Button>
      </Column>
    </CookiesContainer>
  )
}

export default injectIntl(CookieConsent)
