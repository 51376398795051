import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import { IntlContextConsumer, injectIntl } from 'gatsby-plugin-intl'
import { AnchorLink } from 'gatsby-plugin-anchor-links'
import { Link, OutboundLink } from '../Link'
import Logo from '../UI/Logo'
import breakpoints from '../../util/style/breakpoints'
import Divider from '../UI/Divider'
import LanguageSwitch from '../LanguageSwitch'

const Brand = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 72px;
  height: 72px;

  ${breakpoints.md`
    width: 88px;
    height: 88px;
  `};

  ${breakpoints.lg`
    width: 96px;
    height: 96px;
  `};

  ${breakpoints.xl`
    width: 104px;
    height: 104px;
  `};
`
const Wrapper = styled.div`
  background: ${(props) =>
    props.isOpen ? props.theme['$gray-dark'] : 'transparent'};
  margin-bottom: ${(props) => props.isOpen && '56px'};
`

const NavBarHeader = styled.div`
  position: relative;
  background: transparent;
  margin-bottom: -4.688rem;
  z-index: 999;
  padding: 1.5rem 1rem;

  ${breakpoints.md`
    padding: 1.5rem 3rem;
  `};
`

const Nav = styled.nav`
  /* display: ${(props) => (props.isOpen ? 'flex' : 'none')}; */
  display: block;
  height: ${(props) => (props.isOpen ? '100vh' : '0')};
  text-align: center;
  transition: all 0.3s ease-in-out;
  overflow: hidden;

  ${breakpoints.md`
    overflow: unset;
    height: 100%;
    text-align: unset;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  `};
`

const NavItem = styled.div`
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  margin-bottom: 2.5rem;
  width: 100%;

  &:first-child {
    margin-top: 6.5rem;
  }

  ${breakpoints.md`
    &:first-child {
      margin-top: 0;
    }
    margin-top: 0;
    margin-bottom: 0;
    width: auto;
  `};

  ${breakpoints.lg`
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  `};
`

const NavLinkAnchor = styled(AnchorLink)`
  font-size: 0.9375rem;
  font-weight: 600;
  color: ${(props) => props.theme['$headings-color']};

  &.active {
    border-bottom: 2px solid white;
    padding-left: 0 !important;
    padding-right: 0 !important;
    padding-bottom: 10px;
  }
`

const NavNavigateToPage = styled(Link)`
  font-size: 0.9375rem;
  font-weight: 600;
  color: ${(props) => props.theme['$headings-color']};

  &.active {
    border-bottom: 2px solid white;
    padding-left: 0 !important;
    padding-right: 0 !important;
    padding-bottom: 10px;
  }
`

const OutBoundLinkHeader = styled(OutboundLink)`
  font-size: 0.9375rem;
  font-weight: 600;
  color: ${(props) => props.theme['$headings-color']};
`

const MobileNav = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  ${breakpoints.md`
     display: none !important;
  `};
`

const PhoneMobile = styled.div`
  list-style: none;
  transition: all 0.2s ease-in-out;
  display: ${(props) => props.isOpen && 'none'};
`

const MobileNavToggler = styled.div`
  width: 2.5rem;

  &:after,
  &:before {
    background-color: #fff;
    border-radius: 0;
    content: '';
    display: block;
    height: 2px;
    margin: 7px 0;
    transition: all 0.2s ease-in-out;
  }
  &:before {
    transform: ${(props) => props.isOpen && 'translateY(12px) rotate(135deg)'};
  }

  &:after {
    width: ${(props) => (props.isOpen ? '2.5rem' : '1.75rem')};
    transform: ${(props) => props.isOpen && 'translateY(2px) rotate(-135deg)'};
  }
`

class Header extends React.PureComponent {
  state = {
    isOpen: false,
  }

  handleClick() {
    const { isOpen } = this.state
    this.setState({
      isOpen: !isOpen,
    })
  }

  render() {
    const { isOpen } = this.state
    const { intl } = this.props

    return (
      <StaticQuery
        query={graphql`
          query {
            allSanityCompanyInfo {
              edges {
                node {
                  email
                  telephone
                }
              }
            }
          }
        `}
        render={(data) =>
          data.allSanityCompanyInfo.edges[0].node.telephone && (
            <IntlContextConsumer>
              {({ language: currentLocale }) => (
                <Wrapper isOpen={isOpen}>
                  <NavBarHeader>
                    <Brand>
                      <Link to="/">
                        <Logo />
                      </Link>
                    </Brand>
                    <MobileNav>
                      <PhoneMobile isOpen={isOpen}>
                        <OutBoundLinkHeader
                          className="mr-4"
                          to={`tel:${data.allSanityCompanyInfo.edges[0].node.telephone}`}
                          from="navbar"
                        >
                          {data.allSanityCompanyInfo.edges[0].node.telephone}
                        </OutBoundLinkHeader>
                      </PhoneMobile>
                      <MobileNavToggler
                        onClick={() => this.handleClick()}
                        isOpen={isOpen}
                      />
                    </MobileNav>
                    <Nav isOpen={isOpen}>
                      <NavItem>
                        <NavNavigateToPage to="/object-overzicht">
                          {intl.formatMessage({
                            id: 'OFFER',
                          })}
                        </NavNavigateToPage>
                      </NavItem>
                      <NavItem>
                        <NavLinkAnchor
                          to={`/${currentLocale}#${intl.formatMessage({
                            id: 'navigation.nav_1_id',
                          })}`}
                        >
                          {intl.formatMessage({ id: 'navigation.nav_1' })}
                        </NavLinkAnchor>
                      </NavItem>
                      <NavItem>
                        <NavNavigateToPage partiallyActive to="/diensten">
                          {intl.formatMessage({ id: 'navigation.nav_2' })}
                        </NavNavigateToPage>
                      </NavItem>
                      <NavItem>
                        <NavNavigateToPage partiallyActive to="/blog">
                          Blog
                        </NavNavigateToPage>
                      </NavItem>
                      <NavItem>
                        <NavNavigateToPage to="/contact">
                          Contact
                        </NavNavigateToPage>
                      </NavItem>
                      <NavItem>
                        <Divider />
                      </NavItem>
                      <NavItem>
                        <OutBoundLinkHeader
                          to={`tel:${data.allSanityCompanyInfo.edges[0].node.telephone}`}
                          from="navbar"
                        >
                          {data.allSanityCompanyInfo.edges[0].node.telephone}
                        </OutBoundLinkHeader>
                      </NavItem>
                      <NavItem>
                        <LanguageSwitch />
                      </NavItem>
                    </Nav>
                  </NavBarHeader>
                </Wrapper>
              )}
            </IntlContextConsumer>
          )
        }
      />
    )
  }
}

export default injectIntl(Header)
