import React from 'react'
import PropTypes from 'prop-types'

const SVG = ({
  style = {},
  fill = '#b98752',
  width = '24',
  height = '24',
  className = '',
  viewBox = '0 0 24 24',
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    className={`svg-icon ${className || ''}`}
  >
    <path
      fill={fill}
      fillRule="evenodd"
      d="M18 9h1.999L20 6.001C20 5.449 19.551 5 18.999 5H5.001C4.449 5 4 5.449 4 6.001V9h2V7a1 1 0 0 1 1-1h3a1 1 0 0 1 1 1v2h2V7a1 1 0 0 1 1-1h3a1 1 0 0 1 1 1v2zm0 1H4.001C3.449 10 3 10.449 3 11.001V14h18v-2.999c0-.552-.449-1.001-1.001-1.001H18zM3 9.268V6a2 2 0 0 1 2.001-2h13.998C20.104 4 21 4.896 21 6.001v3.267a2 2 0 0 1 1 1.733V18h-2v2h-3v-2H7.001v2H4v-2H2v-6.999a2 2 0 0 1 1-1.733zM7.001 17H21v-2H3v2h4.001zM5 18v1h1v-1H5zm13 0v1h1v-1h-1zM7 9h3V7H7v2zm7 0h3V7h-3v2z"
    />
  </svg>
)

SVG.propTypes = {
  style: PropTypes.object,
  fill: PropTypes.string,
  width: PropTypes.string.isRequired,
  height: PropTypes.string,
  className: PropTypes.string,
  viewBox: PropTypes.string,
}

SVG.defaultProps = {
  style: {},
  height: '100%',
  fill: '#b98752',
  viewBox: '0 0 24 24',
  className: '',
}

export default SVG
