import React from 'react'
import styled from 'styled-components'
import Icon from '../../Icons'

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const Divider = () => (
  <Wrapper className="mb-3 mb-md-0">
    <Icon fill="#b98752" width="48" name="divider" />
  </Wrapper>
)

export default Divider
