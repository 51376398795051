import React from 'react'
import PropTypes from 'prop-types'

const SVG = ({
  style = {},
  fill = '#B98752',
  width = '100%',
  height = '100%',
  className = '',
  viewBox = '0 0 16 16',
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    style={style}
    viewBox={viewBox}
    className={`svg-icon ${className || ''}`}
  >
    <path
      fill={fill}
      fillRule="evenodd"
      d="M16 3.204l-1.714.285 1.143-1.428-2 .571C10.857-.223 6.857 2.918 8 5.488c-4.571 0-6.857-3.427-6.857-3.427s-1.714 2.57 1.143 4.57L.57 6.058c0 1.713 1.143 2.856 3.143 3.427h-2c1.143 2.284 3.143 2.284 3.143 2.284S3.143 13.198 0 13.198C9.429 17.767 15.143 9.2 14.286 4.63L16 3.204z"
    />
  </svg>
)

SVG.propTypes = {
  style: PropTypes.object,
  fill: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
  height: PropTypes.string,
  className: PropTypes.string,
  viewBox: PropTypes.string,
}

SVG.defaultProps = {
  style: {},
  height: '100%',
  viewBox: '0 0 16 16',
  className: '',
}

export default SVG
