import React from 'react'
import { Link as GatsbyLink} from 'gatsby-plugin-intl'
import PropTypes from 'prop-types'
import GoogleAnalytics from 'react-ga'

const Link = ({ to, from, children, className, activeClassName, partiallyActive }) => (
  <GatsbyLink
    to={to}
    partiallyActive={partiallyActive}
    activeClassName={activeClassName}
    className={className}
    onClick={() => {
      GoogleAnalytics.event({
        category: 'Link',
        action: `[clicked] ${from}`,
        label: to,
      })
    }}
  >
    {children}
  </GatsbyLink>
)

Link.propTypes = {
  to: PropTypes.string.isRequired,
  from: PropTypes.string,
  children: PropTypes.any.isRequired,
  className: PropTypes.string,
  activeClassName: PropTypes.string,
  partiallyActive: PropTypes.bool,
}

Link.defaultProps = {
  className: ``,
  from: `unnamed link`,
  activeClassName: `active`,
  partiallyActive: false,
}

export default Link
