import React from 'react'

const Logo = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    viewBox="0 0 48 48"
  >
    <g fill="none" fillRule="evenodd">
      <path fill="#B98752" d="M0 48h48V0H0z" />
      <path
        fill="#171717"
        d="M18.648 11.065l8.074 23.013h-1.656L17.984 13.89l-.828 2.359 6.255 17.827h-1.655l-5.427-15.468-5.429 15.47H9.246l8.074-23.013h1.328zm12.51 0l8.074 23.013h-1.656L30.494 13.89l-.828 2.359 6.255 17.827h-1.655l-5.427-15.468-2.895 8.25-.828-2.358 4.714-13.436h1.328z"
      />
    </g>
  </svg>
)

export default Logo
