import React from 'react'
import PropTypes from 'prop-types'

const SVG = ({
  style = {},
  fill = '#b98752',
  width = '24',
  height = '24',
  className = '',
  viewBox = '0 0 24 24',
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    className={`svg-icon ${className || ''}`}
  >
    <path
      fill={fill}
      fillRule="evenodd"
      d="M6 13H3v4c0 .551.448 1 1 1h16c.552 0 1-.449 1-1v-4H11v2a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1v-2zm11 6H7v2H4v-2a2 2 0 0 1-2-2v-5h19V6a2 2 0 1 0-3.88-.68 3 3 0 0 1 1.357 3.646l-.94-.343A2.002 2.002 0 0 0 16.147 6H16v-.031a2.002 2.002 0 0 0-2.221 1.286l-.356-.13 5.054 1.841-.342.94-5.638-2.053.342-.94.584.213-.584-.212a2.999 2.999 0 0 1 3.337-1.93A3.001 3.001 0 0 1 22 6v11a2 2 0 0 1-2 2v2h-3v-2zm1 0v1h1v-1h-1zM6 19H5v1h1v-1zm1-4h3v-2H7v2z"
    />
  </svg>
)

SVG.propTypes = {
  style: PropTypes.object,
  fill: PropTypes.string,
  width: PropTypes.string.isRequired,
  height: PropTypes.string,
  className: PropTypes.string,
  viewBox: PropTypes.string,
}

SVG.defaultProps = {
  style: {},
  fill: '#b98752',
  height: '100%',
  viewBox: '0 0 24 24',
  className: '',
}

export default SVG
